import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    isChannelPartner:false,
    isSuperUser:false,
    currentOrgID:null,
    currentVehicleID:null,
    currentPolicyID:null,
    currentDeviceID:null,
    currentWifiConnectionID:null,
    currentUserID:null,
    currentAssignedDeviceID:null,
    user:{},
    orgViewMode:false,
    currentVehicleReportID:null,
    currentPolicyReportID:null,
    currentVehicleGroupID:null,
  },
  getters: {
    currentVehicleGroupID : state => {
      return state.currentVehicleGroupID;
    },
    currentPolicyReportID : state => {
      return state.currentPolicyReportID;
    },
    currentVehicleReportID : state => {
      return state.currentVehicleReportID;
    },
    isChannelPartner : state => {
      return state.isChannelPartner;
    },
    isSuperUser : state => {
      return state.isSuperUser;
    },
    currentOrgID : state => {
      return state.currentOrgID;
    },
    currentVehicleID : state => {
      return state.currentVehicleID;
    },
    currentPolicyID : state => {
      return state.currentPolicyID;
    },
    currentDeviceID : state => {
      return state.currentDeviceID;
    },
    currentWifiConnectionID : state => {
      return state.currentWifiConnectionID;
    },
    currentUserID : state => {
      return state.currentUserID;
    },
    currentAssignedID : state => {
      return state.currentAssignedDeviceID;
    },
    currentUser : state => {
      return state.user;
    },
    isOrgViewMode : state => {
      return state.orgViewMode;
    },
  },
  mutations: {
    changeVehicleGroupID(state,payload) {
      state.currentVehicleGroupID=payload;
    },
    changePolicyReportID(state,payload) {
      state.currentPolicyReportID=payload;
    },
    changeVehicleReportID(state,payload)
    {
      state.currentVehicleReportID=payload;
    },
    changeOrgView(state,payload)
    {
      state.orgViewMode=payload;
    },
    logoutUser(state,payload)
    {
      state.user={};
      state.isChannelPartner=false;
      state.isSuperUser=false;
      state.currentOrgID=null;
      state.currentVehicleID=null;
      state.currentPolicyID=null;
      state.currentDeviceID=null;
      state.currentWifiConnectionID=null;
      state.currentUserID=null;
      state.currentAssignedDeviceID=null;
      state.orgViewMode=false;
      state.currentVehicleReportID=null;
      state.currentPolicyReportID=null;
      state.currentVehicleGroupID=null;
    },
    loginUser(state,payload)
    {
      state.user=payload;
    },
    changeCurrentAssignedDevice(state,payload)
    {
      state.currentAssignedDeviceID=payload;
    },
    changeUser(state,payload)
    {
      state.currentUserID=payload;
    },
    changeWifiConnection(state,payload)
    {
      state.currentWifiConnectionID=payload;
    },
    changeDevice(state,payload)
    {
      state.currentDeviceID=payload;
    },

    changePolicy(state,payload)
    {
      state.currentPolicyID=payload;
    },

    changeVehicle(state,payload)
    {
      state.currentVehicleID=payload;
    },

    changeOrg(state,payload)
    {
      state.currentOrgID=payload;
    },

    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {},
})

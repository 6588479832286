<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{ username }}
      </CDropdownHeader>
      <CDropdownItem @click="logout" style="cursor: pointer;"> <CIcon icon="cil-account-logout" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/10.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  data() {
    return {
      username:''
    };  
  },
  created() {
    var vm = this;
    //vm.addCustomers();
    vm.username = vm.$store.getters.currentUser.username;
  },
  methods:{
    logout()
    {
      var vm = this;
      vm.$store.commit("logoutUser", {});
      vm.$router.push("/login");
    }
  }
}
</script>

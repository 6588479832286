<template>
  <div>

    <CToaster class="p-3" placement="top-center">

      <CToast v-for="(toast, index) in toasts" :autohide="true" :color="toast.color"
        class="text-white align-items-center" visible>
        <div class="d-flex">
          <CToastBody white>{{ toast.content }}</CToastBody>
          <CToastClose class="me-2 m-auto" white />
        </div>
      </CToast>

    </CToaster>

    <router-view />

  </div>
</template>
<script>
//import { speed_fox } from "../../providers/data";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
export default {
  name: "approot",
  watch: {},
  setup() {
    return {};
  },
  data() {
    return {
      toasts: []
    };
  },
  async created() {
    var vm = this;
    vm.emitter.on('toast', async (message) => {

      vm.toasts.push(message);

      //console.log('received toast ',message);

    });

  },
  mounted() {




  },
  methods: {

  },
  computed: {

  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
